<template>
  <div>
    <div class="row">
      <div class="col-md-10 payment-summary">
        <div class="dotted-border">
          <div class="header-text">
            Payment Summary
          </div>
          <table>
            <tr v-show="!isHideable">
              <td>Payment Amount</td>
              <td><b>৳ {{ displayAmount }}</b></td>
            </tr>
            <tr v-show="!isHideable" style="border-top: 2px solid #eeeeee;">
              <td>Subtotal</td>
              <td><b>৳ {{ displayAmount }}</b></td>
            </tr>
            <tr v-show="!isHideable">
              <td>Fees</td>
              <td><b>৳ 0.00</b></td>
            </tr>
            <tr style="border-top: 2px solid #eeeeee;">
              <td><b>Total</b></td>
              <td><b>৳ {{ displayAmount }}</b></td>
            </tr>
          </table>
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-btn
                  :elevation="hover ? 24 : 6"
                  color="primary"
                  :disabled="!isValid"
                  height="60px"
                  width="100%"
                  style="font-size: 20px; margin-top: 15px;"
                  @click="$emit('confirmPayment')"
              >
                Confirm Payment
              </v-btn>
            </template>
          </v-hover>
          <div style="display: flex; flex-direction: column; margin-top: 15px">
            <div class="terms-text">By clicking on <b>Confirm Payment</b> you are agreeing to the
              <a @click="goToTerms"
                 style="color: #e2136e">terms & conditions</a></div>
          </div>
          <button id="bKash_button" style="display: none"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentSummary",
  props: ['isValid', 'paymentBody', 'termsAndConditionLink','pageType'],
  computed: {
    isHideable() {
      return (this.pageType === 'payment');
    },
    amount() {
      if (this.paymentBody) return this.paymentBody.amount;
    },
    displayAmount() {
      let amount = this.amount || 0

      return parseFloat(amount).toFixed(2);
    },
  },
  methods: {
    goToTerms() {
      window.open(this.termsAndConditionLink);
    }
  },

}
</script>

<style scoped>
.dotted-border {
  padding: 25px;
  border-left: 2px dashed rgba(0, 0, 0, 0.1);
}

.terms-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
}

table {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  border-collapse: collapse;
}

table tr {
  display: flex;
  justify-content: space-between;
}

table tr td:first-child {
  text-align: left !important;
}

table tr td:last-child {
  text-align: right !important;
}

table tr td:not(:first-child) {
  white-space: nowrap;
}

table tr:last-child {
  font-size: 16px;
  font-weight: 500;
  color: #464646;
}

table td {
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .payment-summary {
    padding: 0;
  }

  .dotted-border {
    border: 0;
    background-color: #f7f7f7;
  }
}
.header-text {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}
</style>
