<template>
  <div class="container payment-page">
    <div class="row">
      <div class="col-md-7">
        <div>
          <span class="preview-header-text">Payment Information</span>
          <v-radio-group v-model="paymentOption" v-if="selectedPaymentOption === 'package'">
            <div class="radio-group-layout">
              <v-radio style="margin-bottom: 0px; margin-right: 10px;" label="Amount" value="amount"></v-radio>
              <v-radio label="Package" value="package"></v-radio>
            </div>
          </v-radio-group>
          <div class="package-div" v-if="packages.length && paymentOption === 'package'">
            <packages :packages="packages"></packages>
          </div>
          <v-text-field
              class="text-input-width inputNumber"
              style="margin-bottom: 10px;  margin-top: 20px"
              v-else
              label="Enter Amount"
              placeholder="Amount"
              dense
              disabled
              outlined>
          </v-text-field>

          <v-text-field
              class="text-input-width inputNumber"
              label="Contact Number"
              placeholder="Enter Contact Number"
              type="text"
              dense
              disabled
              outlined>
          </v-text-field>
        </div>

        <customer-info v-if="hasCustomerInfoFields" :customerInfoFields="customerInfoFields"></customer-info>
      </div>
      <payment-summary class="col-md-5"
                       :termsAndConditionLink="termsAndConditionLink">
      </payment-summary>
    </div>
  </div>
</template>

<script>
import CustomerInfo from "cpp-js-util/components/common/CustomerInfo";
import PaymentSummary from "cpp-js-util/components/common/PaymentSummary";
import Packages from "cpp-js-util/components/common/Packages";

export default {
  name: "PaymentInfo",
  components: {
    CustomerInfo,
    PaymentSummary,
    Packages
  },
  computed: {
    customerInfoFields() {
      return this.$store.getters.getCustomerInfoFields;
    },
    termsAndConditionLink() {
      return process.env.VUE_APP_TERMS_AND_CONDITION_URL
    },
    packages() {
      let pageInfo = this.$store.getters.getPageInfo;
      return pageInfo.packages;
    },
    hasCustomerInfoFields() {
      return this.customerInfoFields.length
    },
    selectedPaymentOption() {
      return this.$store.getters.getPaymentOption
    },
  },
  data() {
    return {
      paymentOption: 'amount',
    }
  },
}
</script>

<style scoped>

.payment-page {
  margin-top: 70px;
}

.radio-group-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.text-input-width {
  width: 70%;
}

.package-div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1 1 auto;
  margin: 21px 0 30px;
}

@media screen and (max-width: 768px) {
  .payment-page {
    margin-top: 0;
  }
}
</style>
