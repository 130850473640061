<template>
  <div class="packages">
    <v-card v-for="(pkg, pos) in packages" :key="pos"
            @click="$emit('packageSelect', pos)"
            class="package"
            :class="isSelectedPackage(pkg) ? 'selected-pkg' : ''">
      <div class="package-title">{{ pkg.name }}</div>
      <div class="package-amount"
           :class="{'large-amount': isLargeAmount(pkg.price), 'medium-amount': isMediumAmount(pkg.price)}">
        ৳ {{ pkg.price }}
      </div>
      <div class="package-des" v-html="pkg.description"></div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    packages: Array,
    selectedPackage: Object,
  },
  methods: {
    isSelectedPackage(pkg) {
      return this.selectedPackage === pkg
    },
    isMediumAmount(amount) {
      return amount.length < 9 && amount.length > 4
    },
    isLargeAmount(amount) {
      return amount.length >= 9
    }
  }
}
</script>

<style scoped>
.packages {
  max-width: 900px;
  display: flex;
  flex-wrap: wrap;
}

.package {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column;
  min-width: 90px;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  flex: 0 0 17%;
  margin: 5px;
  padding: 10px;
  text-align: center;
}

.package-title {
  width: 100%;
  font-size: 12px;
  font-weight: 500;
}

.package-amount {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}

.medium-amount {
  font-size: 15px;
}

.large-amount {
  font-size: 13px;
  white-space: normal;
}

.package-des {
  width: 100%;
  font-size: 10px;
  font-weight: 300;
}

.selected-pkg {
  background-color: #e2136e;
  color: #FFFFFF !important;
}
</style>