import { render, staticRenderFns } from "./Packages.vue?vue&type=template&id=13cb9c24&scoped=true"
import script from "./Packages.vue?vue&type=script&lang=js"
export * from "./Packages.vue?vue&type=script&lang=js"
import style0 from "./Packages.vue?vue&type=style&index=0&id=13cb9c24&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../merchant-portal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13cb9c24",
  null
  
)

export default component.exports