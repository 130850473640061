<template>
  <div style="margin-top: 20px">
    <hr>
    <div class="preview-header-text">
      Customer Information
    </div>
    <div v-for="(fieldName, pos) in customerFields" :key="pos">
      <v-text-field
          class="text-input-width inputNumber"
          :label="fieldName.label"
          :placeholder="fieldName.placeholder"
          type="text"
          outlined
          disabled
          dense
          style="margin-top: 30px">
      </v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerInfo",
  props: ['customerInfoFields'],
  data() {
    return {
      customerFields: this.customerInfoFields,
    }
  }
}
</script>

<style scoped>
hr {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #eeeeee;
}

.text-input-width {
  width: 70%;
}
.preview-header-text {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #363636
}
</style>
